import React, {useContext, useEffect, useState} from "react";
import {
    DayView,
    Scheduler,
    WorkWeekView,
} from "@progress/kendo-react-scheduler";
import {Meeting} from "../models/Meeting";
import {Configuration} from "../Configuration";
import Assistant from "../Components/Assistant";
import {Message} from "@progress/kendo-react-conversational-ui";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

// 2024-03-06T13:00:00"
export const Calendar = () => {
    const {token}: IAuthContext = useContext(AuthContext)
    const [meetings, setMeetings] = useState<Meeting[]>([]);

    useEffect(() => {
        fetchMeetings().then();
    }, [])


    const fetchMeetings = async (reload: boolean = false) => {
        let response = await fetch(`${Configuration.baseApiUrl}/meetings/?reload=${reload}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })

        let data = await response.json() as any[];
        let meetings: Meeting[] = [];
        for (let item of data) {
            let meeting = {
                id: item.id,
                title: item.title,
                start: new Date(item.start),
                end: new Date(item.end),
                recurrenceRule: item.recurrenceRule,
                description: item.description,
                // recurrenceExceptions: [
                //     new Date("2019-09-25T12:00:00.000Z"),
                //     new Date("2019-09-26T12:00:00.000Z"),
                // ],
            } as Meeting;
            meetings.push(meeting);
        }
        setMeetings(meetings);
    }

    const onChatReply = async (message: Message) => {
        console.log(message);
        await fetchMeetings(true);
    };

    return <div style={{display: "grid", gridTemplateRows: "auto 1fr auto", gridTemplateColumns: "2fr 1fr", height: "calc(100vh - 75px)"}}>
        <div style={{gridRow: "1", gridColumn: "1"}}>
            <h1>Calendar</h1>
        </div>
        <div style={{gridRow: "2", gridColumn: "1", overflowY: "scroll"}} >
            <Scheduler defaultView={"day"}
                       height={"100%"}
                       editable={true}
                       timezone={"Etc/UTC"}
                       data={meetings} >
                <DayView
                    showWorkHours={false}
                    currentTimeMarker={true}
                    workDayStart={"08:30"} workDayEnd={"20:00"}
                    slotDivisions={1}/>
                <WorkWeekView
                    showWorkHours={false}
                    currentTimeMarker={true}
                    workDayStart={"08:30"} workDayEnd={"20:00"}
                    slotDivisions={1}/>
            </Scheduler>
        </div>
        <div style={{gridRow: "2", gridColumn: "2"}} >
            <Assistant onReply={onChatReply} />
        </div>

        <div style={{gridRow: "3", gridColumn: "1"}}>
            {/*<h1>Footer</h1>*/}
        </div>
    </div>;
}

export default Calendar;