import {Helpers} from "./Helpers";

export class ChatClient {
    messages: ChatMessage[] = [];
    apiBaseUrl: string
    authenticationToken?: string;

    constructor(apiBaseUrl: string, initialPrompt?: string, authenticationToken: string = null) {
        this.apiBaseUrl = apiBaseUrl;
        this.messages.push(new ChatMessage("system",
            initialPrompt ?? "You're an AI assistant"));
        this.authenticationToken = authenticationToken;
    }

    async *sendMessageStream(message: ChatMessage): AsyncIterable<string> {
        this.messages.push(message);

        console.log(JSON.stringify(this.messages));
        let headers: HeadersInit;

        if (this.authenticationToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.authenticationToken}`
            };
        } else {
            headers = {
                'Content-Type': 'application/json'
            };
        }
        let response = await fetch(`${this.apiBaseUrl}/stream`, {
            method: 'POST',
            body: JSON.stringify(this.messages),
            headers: headers
        });
        const reader = response.body.getReader();

        let fullText = "";
        while (true) {
            const { value, done } = await reader.read()
            if (done) {
                break;
            }
            if (value == null) {
                continue
            }

            let text = new TextDecoder().decode(value);
            let lines = text.split("\n");
            for (let line of lines) {
                if (!line.startsWith("data: ")) {
                    continue;
                }
                if (line === "data: [DONE]") {
                    break;
                }

                // line starts with "data: "
                let json = line.substring(5);
                let object = JSON.parse(json);
                let choice = object.choices[0].delta;
                // console.log(choice.content, choice)
                if (choice.content) {
                    // slow delay looks nicer and a bit like a typewriter
                    await Helpers.sleep(15)
                    // append text
                    fullText += choice.content;
                    // parse as markdown and show html
                    const html = await Helpers.parseMarkDown(fullText)

                    yield html
                    // yield fullText;
                }
            }
        }

        let answer = {
            "role": "assistant",
            "content": fullText
        } as ChatMessage;
        this.messages.push(answer)
    }
}

export class ChatMessage {
    role: string;
    content: string;

    constructor(role: string, content: string) {
        this.role = role;
        this.content = content;
    }
}
