import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import '@progress/kendo-theme-default/dist/all.css';
import '@progress/kendo-theme-material/dist/all.css';
import './Overrides.css';

import NavigationHeader from "./Components/NavigationHeader";
import AppRoutes from "./AppRoutes";
import {Route, Routes} from "react-router-dom";
import {Authenticator} from "./Components/Authenticator";
import {Configuration} from "./Configuration";

function App() {
  return (
      <div className="App">
          <Authenticator authConfig={Configuration.authenticationConfig}>
              <NavigationHeader></NavigationHeader>
              <Routes>
                  {AppRoutes.map((route, index) => {
                      const {element, ...rest} = route;
                      return <Route key={index} {...rest} element={element}/>;
                  })}
              </Routes>
          </Authenticator>
      </div>
  );
}

export default App;
