import {marked} from "marked";
import * as DOMPurify from "dompurify";

export class Helpers {
    static textToHtml(text: string): string {
        if (text == null) {
            return "";
        }
        return text.replace(/\n/g, "<br>")
    }

    static sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

    static async parseMarkDown(text: string, sanitize: boolean = true): Promise<string> {
        // parse markdown
        // const markdown = marked.parse(text, { mangle: false, headerIds: false });
        const markdown = await marked.parse(text);

        // return if we don't need to sanitize
        if (!sanitize) {
            return markdown;
        }

        // return a sanitized version
        return DOMPurify.sanitize(markdown);
    }

    static getGetOrCreateKey(name: string): string {
        let deviceKey = localStorage.getItem(name)
        if (deviceKey) {
            return deviceKey;
        }

        deviceKey = this.generateKey()
        localStorage.setItem(name, deviceKey);

        return deviceKey;
    }

    static generateKey(): string {
        const array = new Uint8Array(32);
        window.crypto.getRandomValues(array);

        return  this.toBase64(array)
    }

    static toBase64(buffer: ArrayBuffer): string {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}