import React from "react";
import Calendar from "./views/Calendar";

const AppRoutes = [
    {
        index: true,
        element: <Calendar />
    }
]

export default AppRoutes;