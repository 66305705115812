import React from "react";

export const NavigationHeader = () => {
    return <nav className="navbar navbar-expand-lg navbar-light bg-light p-2" style={{height: "75px"}}>
        <div className="container-fluid">
            <a className="navbar-brand" href="/">
                <img id="logoImage" src="/AiBabs-logo.svg" style={{height: "65px", padding: "8px 12px"}} alt="iBabs"/>
                 Executive Assistant V0</a>
        </div>
    </nav>
}

export default NavigationHeader;