import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Chat,
    ChatActionExecuteEvent,
    ChatMessageBoxProps,
    ChatMessageTemplateProps,
    Message
} from "@progress/kendo-react-conversational-ui";
import {ChatClient, ChatMessage} from "../Code/ChatClient";
import {Configuration} from "../Configuration";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";

const MessageTemplate = (props: ChatMessageTemplateProps) => {
    let message = props.item;
    let formattedText = { __html: message.text };
    return (
        <div className="k-chat-bubble">
            <div dangerouslySetInnerHTML={formattedText} />
        </div>
    );
};

const customMessage = (props: ChatMessageBoxProps) => {
    console.log(props.messageInput);

    const handleKeyDown = (e: any) => {
        // console.log(e.target.value);
        // props.messageInput.props.value = e.target.value;
    };

    return (
        <>
            <textarea style={{width: "100%", height: "100px"}}
                      placeholder={props.messageInput.props.placeholder}
                      onKeyDown={handleKeyDown}></textarea>

            {props.sendButton}
            {props.messageInput}
        </>
    );
};

export type AssistantProps = {
    onReply?: (message: Message) => void;
}

export const Assistant = ({ onReply }: AssistantProps) => {
    const user = {
        id: 1,
        // avatarUrl: "https://demos.telerik.com/kendo-ui/content/web/Customers/RICSU.jpg",
        avatarAltText: "AiBabs"
    };

    const bot = { id: 0 };

    const initialMessages = [
        {
            author: bot,
            timestamp: new Date(),
            text: "Hello, welcome to the AiBabs executive assistant, AMA.",
            suggestedActions: [
                {
                    value: "Give me a summary of last year's financials results.",
                    type: "reply",
                },
                {
                    value: "Set up a meeting for next Wednesday with my team at 10:00.",
                    type: "reply",
                },
                {
                    value: "Clear my schedule for Wednesday.",
                    type: "reply",
                },
                {
                    value: "Give a summary of last weeks MT meeting.",
                    type: "reply",
                },
                {
                    value: "Create an action item for John to create a slide deck making the case for new developer machines for next Wednesday.",
                    type: "reply",
                },
                // {
                //     title: "Start a Teams call with Raymond",
                //     value: "https://teams.microsoft.com/l/call/0/0?users=raymond@ibabs.eu",
                //     type: "openUrl",
                // },
                // {
                //     title: "Start a Teams chat with Raymond",
                //     value: "https://teams.microsoft.com/l/chat/0/0?users=raymond@ibabs.eu",
                //     type: "openUrl",
                // },
                // {
                //     title: "Place a call",
                //     value: "555-123-456",
                //     type: "call",
                // },
                // {
                //     title: "A custom action",
                //     value: "Custom action clicked",
                //     type: "alert",
                // },
            ],
        },
    ];
    const [messages, setMessages] = React.useState<Message[]>(initialMessages);
    const [chatClient, setChatClient] = useState<ChatClient>();
    const {token}: IAuthContext = useContext(AuthContext)

    useEffect(() => {
        console.log("init chat client")
        const chatClient = new ChatClient(
            Configuration.baseApiUrl + "/chat",
            "You are an helpful executive assistant for an important business executive handling their day to day chores.",
            token);

        setChatClient(chatClient);

    }, []);

    useEffect(() => {
        let elems = document.getElementsByClassName("k-message-list-content")
        if (elems.length) {
            elems[0].scrollTop = elems[0].scrollHeight + 500;
        }
    }, [messages]);

    const addNewMessage = async (event: any) => {
        let botMessage = {
            author: bot,
            timestamp: new Date(),
            text: "Working....",
            typing: true
        } as Message;
        setMessages([...messages, event.message, botMessage]);

        let message = new ChatMessage("user", event.message.text);

        for await (const text of chatClient.sendMessageStream(message) ) {
            botMessage = {
                author: bot,
                timestamp: new Date(),
                text: text,
                typing: false
            } as Message;
            // console.log(text);
            setMessages([...messages, event.message, botMessage]);
        }

        onReply?.(botMessage);
    };

    const onAction = (event: ChatActionExecuteEvent) => {
        if (event.action.type === "alert") {
            setMessages([...messages, { author: user, text: event.action.value }]);
        }
    };

    // return <div style={{backgroundColor: "#f60", flex: "1", minHeight: "0"}}>
    return <div className={"w-100 h-100"}>
        <Chat
            user={user}
            messages={messages}
            messageTemplate={MessageTemplate}
            onMessageSend={addNewMessage}
            onActionExecute={onAction}
            placeholder={"Type a message..."}
            // messageBox={customMessage}
            // showToolbar={true}
            // width={"100%"}
            // className={"h-100 w-100"}
        />
    </div>
}

export default Assistant